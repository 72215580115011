.PhoneInputInput{
  height: 30px;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.PhoneInputInput:focus{
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%)
}
@font-face
{
    font-family: 'Poppins-Black';
  src: url('./fonts/Poppins-Black.eot');
   src:   url('./fonts/Poppins-Black.eot?#') format('embedded-opentype');
   src:   url('./fonts/Poppins-Black.woff') format('woff');
   src:   url('./fonts/Poppins-Black.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-BlackItalic';
  src: url('./fonts/Poppins-BlackItalic.eot');
  src:    url('./fonts/Poppins-BlackItalic.eot?#') format('embedded-opentype');
  src:    url('./fonts/Poppins-BlackItalic.woff') format('woff');
  src:    url('./fonts/Poppins-BlackItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-Bold';
  src: url('./fonts/Poppins-Bold.eot');
  src:    url('./fonts/Poppins-Bold.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-Bold.woff') format('woff'),
      url('./fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-BoldItalic';
  src: url('./fonts/Poppins-BoldItalic.eot'),
      url('./fonts/Poppins-BoldItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-BoldItalic.woff') format('woff'),
      url('./fonts/Poppins-BoldItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-ExtraBold';
  src: url('./fonts/Poppins-ExtraBold.eot'),
      url('./fonts/Poppins-ExtraBold.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-ExtraBold.woff') format('woff'),
      url('./fonts/Poppins-ExtraBold.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-ExtraBoldItalic';
  src: url('./fonts/Poppins-ExtraBoldItalic.eot'),
      url('./fonts/Poppins-ExtraBoldItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-ExtraBoldItalic.woff') format('woff'),
      url('./fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-ExtraLight';
  src: url('./fonts/Poppins-ExtraLight.eot'),
      url('./fonts/Poppins-ExtraLight.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-ExtraLight.woff') format('woff'),
      url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-ExtraLightItalic';
  src: url('./fonts/Poppins-ExtraLightItalic.eot'),
      url('./fonts/Poppins-ExtraLightItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-ExtraLightItalic.woff') format('woff'),
      url('./fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-Italic';
  src: url('./fonts/Poppins-Italic.eot'),
      url('./fonts/Poppins-Italic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-Italic.woff') format('woff'),
      url('./fonts/Poppins-Italic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-Light';
  src: url('./fonts/Poppins-Light.eot'),
      url('./fonts/Poppins-Light.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-Light.woff') format('woff'),
      url('./fonts/Poppins-Light.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-LightItalic';
  src: url('./fonts/Poppins-LightItalic.eot'),
      url('./fonts/Poppins-LightItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-LightItalic.woff') format('woff'),
      url('./fonts/Poppins-LightItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-Medium';
  src: url('./fonts/Poppins-Medium.eot'),
      url('./fonts/Poppins-Medium.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-Medium.woff') format('woff'),
      url('./fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-MediumItalic';
  src: url('./fonts/Poppins-MediumItalic.eot'),
      url('./fonts/Poppins-MediumItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-MediumItalic.woff') format('woff'),
      url('./fonts/Poppins-MediumItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-Regular';
  src: url('./fonts/Poppins-Regular.eot'),
      url('./fonts/Poppins-Regular.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-Regular.woff') format('woff'),
      url('./fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-SemiBold';
  src: url('./fonts/Poppins-SemiBold.eot'),
      url('./fonts/Poppins-SemiBold.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-SemiBold.woff') format('woff'),
      url('./fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-SemiBoldItalic';
  src: url('./fonts/Poppins-SemiBoldItalic.eot'),
      url('./fonts/Poppins-SemiBoldItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-SemiBoldItalic.woff') format('woff'),
      url('./fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-Thin';
  src: url('./fonts/Poppins-Thin.eot'),
      url('./fonts/Poppins-Thin.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-Thin.woff') format('woff'),
      url('./fonts/Poppins-Thin.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins-ThinItalic';
  src: url('./fonts/Poppins-ThinItalic.eot'),
      url('./fonts/Poppins-ThinItalic.eot?#') format('embedded-opentype'),
      url('./fonts/Poppins-ThinItalic.woff') format('woff'),
      url('./fonts/Poppins-ThinItalic.ttf') format('truetype');
}

/* font-family: 'Poppins', sans-serif; */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* startup & investor form  */

.ant-form-item {
  margin-bottom: 20px;
  padding: 0;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding-bottom: 4px;
}

.ant-form-vertical .ant-form-item-label>label, .ant-col-24.ant-form-item-label>label, .ant-col-xl-24.ant-form-item-label>label {
  font-family: poppins;
  font-weight: 700;
  font-size: 11.5px;
  color: black;
}

.ant-input-number,
.ant-input {
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.ant-input-affix-wrapper > input.ant-input{
  height: 30px;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary {
  height: 30px;
}

textarea {
  height: 70px!important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  height: 30px;
}

.ant-select-arrow {
  color: #036CAC;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 28px;
  color: grey;
}

.en_btn-next, .en_btn-next:focus, .en_btn-next:hover, .en_btn-next:active, .en_btn-next:before {
  font-family: poppins;
  padding: 3px 22px 11px 22px;
  font-size: 15px;
  letter-spacing: 2.15px;
  font-weight: 700;
  color: #036CAC;
  background-color: #FFFFFF;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
}

.en_btn-prev, .en_btn-prev:focus, .en_btn-prev:hover, .en_btn-prev:active, .en_btn-prev:before {
  font-family: poppins;
  padding: 3px 22px 11px 22px;
  font-size: 15px;
  letter-spacing: 2.15px;
  font-weight: 700;
  color: #036CAC;
  background-color: #FFFFFF;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
}

/* ----------- */

.scroll-hide {
  overflow: hidden;
  background-color: white;
}

.relative {
  position: relative;
}

.block{
  display: block;
}

.flex{
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}

.shadow{
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)!important;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)!important;
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)!important;
}

.custom-inputs{
  border: none;
  border-radius: 7px;
  padding: 4px 11px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)!important;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)!important;
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)!important;
}

.custom-inputs:focus{
  outline: none;
}

.custom-inputs:focus-visible{
  border: 1px solid #1890ff;
}

.custom-inputs:hover{
  border: 1px solid #1890ff;
  /* box-shadow: 0 0 1px 1px #188fff34!important;
  -webkit-box-shadow: 0 0 1px 1px #188fff34!important;
  -moz-box-shadow: 0 0 1px 1px #188fff34!important; */
}
.custom-inputs::shadow{
  color: #036CAC;
}
input[disabled] {
  cursor: not-allowed;
  opacity: .6;
}

.topBar {
  height: 35px;
  /* background: #60b244; */
  background: black;
  padding: 0 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.6s;
  z-index: 10;
}

.topBar-hidden{
  top: -35px;
}

.en_topBar-link {
  font-family: poppins;
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 1.8px;
  color: white;
  background-color: #036CAB;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.en_topBar-menu {
  font-family: poppins;
  font-weight: 400;
  font-size: 13px;
  color: white;
  padding: 0 15px;
}

.topBar-icon{
  max-width: 20px;
  cursor: pointer;
}

.topbar-flex{
  display: block;
}

.topbar-user-menu{

}

.en_lang-dropdown, .en_lang-dropdown:hover, .en_lang-dropdown:active {
  font-family: poppins;
  font-weight: 200;
  font-size: 17px;
  color: white;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  padding-bottom: 3px;
}

/* Header Menu */

.header-row{
  padding: 15px 10px 15px 60px;
  position: fixed;
  top: 35px;
  transition: top 0.6s;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.header-fixed-row{
  /* top: 0;
  padding: 15px 10px 15px 60px;
  position: fixed;
  background-color:  #60b244;
  opacity: 0.9;  */
  top: 0;
    padding: 15px 10px 15px 60px;
    position: fixed;
    background-color: white;
    opacity: 1;
    -webkit-box-shadow: 0 10px 5px #888888;
    -moz-box-shadow: 0 10px 5px #888888;
    box-shadow: 0 10px 10px #dcd9d9;
}

.hidden-header-row{
  padding: 15px 10px 15px 60px;
  visibility: hidden;
  position: relative;
}

.en_logo {
  max-width: 87%;
  margin-top: -7px;
}

.header-menu {
  border-bottom: none;
  font-family: roboto;
  font-weight: 700;
}

.header-fixed-menu {
  border-bottom: none;
  font-family: roboto;
  font-weight: 700;
  background-color: transparent;
}

.header-menu>.header-menu-list a {
  color: black;
  font-size: 16px;
  font-family: poppins;
  font-weight: 400;
  margin: 0 -3px;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  float: right;
  margin-top: 18px;
  margin-left: 10px;
  font-size: 13px;
}

.ant-menu-item .ant-menu-item-icon+span, .ant-menu-submenu-title .ant-menu-item-icon+span, .ant-menu-item .anticon+span, .ant-menu-submenu-title .anticon+span {
  font-size: 16px;
  font-family: poppins;
  font-weight: 400;
}

/* Section one */

.ant-carousel .slick-dots{
  margin: 0;
  padding: 0;
}

.ant-carousel .slick-list{
  margin: 0;
  width: 100%;
}

.slider1-section {
  background: white;
  position: relative;
}

.slider1 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}

.home-title {
  position: absolute;
  color: white;
  font-family: poppins;
  font-size: 54px;
  font-weight: 700;
  top: 28%;
  left: 4%;
  right: 38%;
  letter-spacing: 3px;
  line-height: 1.2;
  margin-bottom: 0.7em;
}

.btn-home {
  position: absolute;
  gap: 35px!important;
  bottom: 20%;
  left: 7%;
}

.btn-slide:hover,
.btn-slide {
  padding: 10px 26px 35px 26px;
  letter-spacing: 1.7px;
  font-family: Roboto;
  border: 1.5px solid #fff;
  font-size: 15.5px;
  background-color: transparent;
  color: white;
}

.btn-slide-damya:hover,
.btn-slide-damya {
  padding: 10px 26px 35px 26px;
  letter-spacing: 1.7px;
  font-family: Roboto;
  border: 1.5px solid #60b244;
  font-size: 15.5px;
  background-color: #60b244;
  color: white;
}

.break-line-1::after{
  content: "\a";
  white-space: pre;
}

.break-line-2::after{
  content: "\a";
  white-space: pre;
}

/* ****************slider2***************** */

.en_slider2-section {
  background: white;
  position: relative;
  padding: 0;
}

.impact-title {
  position: absolute;
  color: white;
  font-family: poppins;
  font-size: 85px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 1.2;
  letter-spacing: 8px;
  top: 17%;
  left: 9%;
}

.impact-text{
  position: absolute;
  color: white;
  font-family: poppins;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.1;
  bottom: 14%;
  right: 53%;
  left: 4%;
}

.break-line-impact::after{
  content: "\a";
  white-space: pre;
}

.en_slider2 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}

.hidden-slider-height {
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: -1;
}

/* Services Section */

.en_title-section-service {
  font-size: 40px;
  color: #606060;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 18%;
  padding-bottom: 60px;
}

.en_service-section {
  background: white;
  padding: 60px 55px;
  background: url(./img/invest-background.png) no-repeat 0%;
}

.en_img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 22%;
  padding-bottom: 15px;
}

.en_service-title {
  font-family: "Poppins";
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.en_service-text {
  font-family: "Poppins";
  text-align: center;
  font-size: 11px;
  font-weight: 200;
  color: #000000;
}

.en_service-row-2-pad {
  padding: 90px 8% 0 8%;
}

/* Actuality Section */

.title-section-actuality {
  font-size: 40px;
  color: #606060;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 18%;
  padding-bottom: 60px;
}

.actuality-section {
  background: white;
  margin: 60px 130px;
  margin-bottom: 130px;
}

.actuality-card{
  margin: 0 30px 15px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.card-content{
  padding: 0 10px;
  height: 300px;
}

.actuality-img{
  width: 100%;
  height: 200px;
  display: block!important;
}

.new-actuality{
  font-family: 'poppins';
  font-weight: 300;
  font-size: 11px;
  color: white;
  background-color: #60b244;
  padding: 7px 20px;
  top: 35.6%;
  position: absolute;
}

.actuality-date{
  font-family: 'poppins';
  color: #606060;
  font-size: 14px;
  padding-top: 35px;
  margin: 0;
}

.actuality-title {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  padding-top: 15px;
}

.actuality-text{
  font-family: 'poppins';
  font-weight: 500;
  font-size: 16px;
  padding-top: 0 10%;
  margin-bottom: 80px;
}

.actuality-btn:focus,
.actuality-btn:hover,
.actuality-btn {
  background-color: #60b244;
  color: white;
  font-family: "Open Sans";
  font-size: 9px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 20px 15px;
  position: absolute;
  bottom: 5%;
}

.actuality-btn-arrow{
  border-radius: 50%;
  padding: 3px;
  font-size: 8px;
  border: 1px solid white;
  margin: 0 15px!important;
}

.actuality-next{
  top: 40%;
  right: -6%;
}
.actuality-prev{
  top: 40%;
  left: -5%;
}

.show-all-actuality-btn:focus,
.show-all-actuality-btn:hover,
.show-all-actuality-btn{
  float: right;
  background-color: #60b244;
  color: white;
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 35px 20px;
  margin-right: 28px;
  margin-top: 30px;
  display: flex;
  align-items: baseline;
}

.actuality-arrow-btn {
  border-radius: 50%;
  padding: 5px;
  font-size: 9px;
  border: 3px solid white;
  margin-right: 20px;
  margin-left: 30px!important;
}

/* Actuality Page section */

.title-section-actuality-single {
  font-size: 38px;
    color: #036CAC;
    font-family: "Poppins-Bold";
    padding-bottom: 80px;
    text-align: center;
    letter-spacing: 3px;
}

.actuality-section-single {
  background-color: white;
  padding: 80px 128px 100px 128px;
}


/* Partners Section */

.title-section-partner {
  font-size: 38px;
    color: #036CAC;
    font-family: "Poppins-Bold";
    padding-bottom: 80px;
    text-align: center;
    letter-spacing: 3px;
}

.partner-section {
  background-color: white;
  padding: 80px 70px 100px 70px;
}

.partner-col{
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
}

.partner-img{
  position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    padding: 75px;
}

.partner-home-col{
  position: relative;
  height: 150px;
}
.partner-next {
  right: 0%;
  top: 28%;
}

.partner-prev {
  left: 0%;
  top: 28%;
}
/* Startup Section */

.en_startup-section-non-relative {
  background: #dcdcdc;
  padding: 0;
}

.en_title-section-startup {
  font-size: 55px;
  color: #22aae2;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding-bottom: 45px;
}

.en_startup-section-relative {
  background: white;
  padding: 0;
  margin: 0;
  position: relative;
}

.en_text-section {
  text-align: center;
  color: #010101;
  font-weight: 300;
  font-family: poppins;
  font-size: 20px;
  padding: 0 22%;
  line-height: 22px;
}

.en_office-img {
  max-width: 53%;
  left: 0px;
  top: 0;
  position: absolute;
  mix-blend-mode: multiply;
}

.en_startup-hidden-height {
  max-width: 53%;
  visibility: hidden;
  z-index: -1;
}

.en_vector-woman {
  position: absolute;
  right: 55%;
  max-width: 12%;
  bottom: 9%;
}

.en_rectangle-img {
  max-width: 72%;
  position: absolute;
  right: -5%;
  top: 6%;
}

.en_for-startup-title {
  position: absolute;
  right: 5%;
  left: 35.5%;
  top: 15%;
  font-size: 28px;
  letter-spacing: 3.5px;
  color: white;
  font-family: "poppins";
  font-weight: 700;
  line-height: 1;
}

.en_startup-text {
  position: absolute;
  right: 5%;
  left: 35.5%;
  top: 23%;
  font-size: 16px;
  letter-spacing: 3px;
  color: white;
  font-family: "poppins";
  font-weight: 400;
  line-height: 1.5;
}

.en_startup-text-one {
  position: absolute;
  font-family: "poppins";
  font-size: 18px;
  font-weight: 200;
  right: 11%;
  left: 35.5%;
  bottom: 32%;
  color: white;
  letter-spacing: 2px;
}

.en_read-more:hover,
.en_read-more {
  background-color: #60b244;
  color: white;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 2px;
  position: absolute;
  border: none;
  right: 1%;
  bottom: 8%;
  padding: 8px 0 35px 15px;
  display: flex;
  align-items: baseline;
}

.en_arrow-mobile {
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  border: 3px solid white;
  margin: 0 15px!important;
}

/* Investment Section */

.en_title-section-invest {
  font-size: 55px;
  color: #22aae2;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  padding-bottom: 45px;
}

.en_invest-section-non-relative {
  background: #dcdcdc;
  padding: 0;
}

.en_invest-section-relative {
  background: white;
  margin: 70px 0;
  position: relative;
}

.en_invest-woman {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 53%;
}

.en_invest-hidden-height {
  max-width: 53%;
  visibility: hidden;
  z-index: -1;
}

.en_vector-woman-invest {
  position: absolute;
  right: 55%;
  max-width: 12%;
  bottom: 9%;
}

.en_rectangle-grey {
  position: absolute;
  left: 0px;
  top: 6%;
  max-width: 63.5%;
}

.en_for-investor-title {
  position: absolute;
  right: 40%;
  left: 2%;
  top: 30%;
  font-size: 28px;
  letter-spacing: 3.5px;
  color: white;
  font-family: "poppins";
  font-weight: 700;
  line-height: 1;
}

.en_invest-text {
  position: absolute;
  left: 2%;
  right: 40%;
  top: 49%;
  font-size: 16px;
  color: white;
  font-family: "poppins";
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 3px;
}

.en_invest-text-one {
  position: absolute;
  font-family: "poppins";
  font-size: 18px;
  font-weight: 200;
  left: 2%;
  right: 40%;
  bottom: 25%;
  color: white;
  letter-spacing: 2px;
}

.en_invest-read-more:hover,
.en_invest-read-more {
  background-color: #60b244;
  color: white;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 2px;
  position: absolute;
  border: none;
  right: 37.5%;
  bottom: 5%;
  padding: 8px 0 35px 15px;
  display: flex;
  align-items: baseline;
}

/* Board Section */

.title-section-board {
  font-size: 38px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 80px;
  text-align: center;
  letter-spacing: 3px;
}

.landing-page-board-title{
  font-size: 40px;
  color: #606060;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 600;
  text-align: center;
  padding: 0 18%;
  padding-bottom: 20px;
}

.board-text{
  text-align: center;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 18px;
  padding: 0 10%;
  margin-bottom: 80px;
}

.board-slider{
  padding: 0 80px;
}

.board-col{
  padding: 0 15px;
}

.board-next {
  right: 3%;
  top: 35%;
}

.board-prev {
  left: 4%;
  top: 35%;
}

.board-section-non-relative {
  background-color: white;
  padding: 80px 0 100px 0;
}

.landing-page-board-section{
  margin: 0;
  padding: 0;
}

.board-section-relative {
  background: white;
  padding: 0;
  margin: 0;
  position: relative;
}

.board-member-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 45%;
  padding-bottom: 15px;
}

.member-border {
  border: 1px solid #7d8082;
  padding: 7px 11px;
}

.member-info {
  background-color: #036cab;
  padding: 1px;
  overflow: hidden;
}

.member-content {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  color: #606060;
  font-size: 10px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1;
}

.member-name {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  margin: 3px 0;
}

.member-position {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 9px;
  text-align: center;
  margin: 0;
}

.flex-board {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -20px
}

.member-icons {
  margin: 0;
  margin-bottom: 0;
  margin-top: auto;
  padding: 5px 5px;
  align-self: center;
  max-width: 10%;
  cursor: pointer;
}

.about-team {
  font-family: poppins;
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  padding-right: 170px;
}

.pad-board-row {
  padding: 0 170px;
}

.board-vector {
  position: absolute;
  top: -15%;
  left: -4%;
  max-width: 40%;
}

/* testimony Section */

.en_title-section-testimony {
  font-size: 40px;
  color: #606060;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  padding-bottom: 20px;
}

/* .en_text-section-testimony {
  font-family: poppins;
  font-weight: 200;
  font-size: 16px;
  padding-top: 10px;
} */

.en_testimony-section-non-relative {
  background: white;
  padding: 100px 100px 70px 100px;
  margin: 0;
}

.en_testimony-section-relative {
  background: #dcdcdc;
  padding: 30px 0 0 0;
  margin: 0;
  position: relative;
}
 
.arrow {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow-size {
  max-width: 70%;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 12%;
  top: 28%;
}

.prev {
  left: 12%;
  top: 28%;
}

.en_testimony-name {
  font-family: poppins;
  font-weight: 600;
  text-align: center;
  font-size: 27px;
  color: #036cab;
  padding-top: 15px;
  padding-bottom: 20px;
  margin: 0;
}

.en_subtitle-section-testimony {
  font-family: poppins;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.5px;
  margin: 0;
  padding-bottom: 5px;
}

.en_text-testimony {
  font-family: poppins;
  font-weight: 200;
  font-size: 18px;
  letter-spacing: 1.2px;
  padding-bottom: 5px;
}

.en_testimony-read-more:hover,
.en_testimony-read-more{
  background-color: #60b244;
    color: white;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 2px;
    border: none;
    padding: 8px 0 35px 15px;
    display: flex;
    align-items: baseline;
}

.en_video {
  padding: 0 0 20px 0;
}

.player-wrapper {
  position: relative;
  margin: 0 15%;
  padding-top: 39.37%; /* 720 / 1280 = 0.5625 */
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

/* partner Section */

.en_title-section-partner {
  font-size: 40px;
  color: #606060;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 18% 50px 18%;
}

.en_partner-section-non-relative {
  background: white;
  padding: 0 0 30px;
}

.en_partner-section-relative {
  background: white;
  padding: 30px 0 0 0;
  position: relative;
}

.en_partner-logo-spacing {
  padding: 0 120px 20px 120px;
  vertical-align: bottom;
}

.en_partners-spacing {
  max-width: 45%;
  padding-bottom: 50px;
}

/* number Section */

.title-section-number {
  font-size: 40px;
  color: #606060;
  font-family: "Poppins";
  letter-spacing: 5px;
  font-weight: 600;
  text-align: center;
  margin-top: 45px;
  padding: 0 18% 50px 18%;
}

.number-section-non-relative {
  background: white;
  padding: 0 0 30px;
}

.row-numbers{
  padding: 0 60px;
}

/* footer Section */

.footer-section-relative {
  padding: 110px 55px;
  padding: 10px 3% 100px 3%;
  margin: 0;
  position: relative;
  z-index: 5;
}

.footer-section-relative::after {
  content: "";
  background: url(./img/footer-img.png) no-repeat;
  background-size: cover;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.footer-padding{
  padding-left: 16%;
  padding-right: 13%;
  padding-top: 80px;
}

.logo-footer {
  max-width: 60%;
  padding: 0 0 40px 0;
}

.footer-col-padding{
  padding-top: 40px;
}

.social-icon-inline{
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.footer-email{
  text-align: center;
  font-family: poppins;
  font-weight: 600;
  color: black;
  font-size: 14px;
  margin-top: 40px;
  letter-spacing: 2px;
}

.social-icon {
  padding-right: 7px;
  padding-left: 7px;
  width: 40px;
  height: auto;
  /* padding-bottom: 8px; */
}
.social-icon-facebook{
  padding-right: 7px;
  padding-left: 7px;
  width: 63px;
  height: auto;
  /* padding-bottom: 8px; */
}

.footer-item-title {
  font-family: poppins;
  font-weight: 700;
  font-size: 18px;
  color: black;
  padding-bottom: 25px;
  padding-right: 10px;
}

.footer-item-title-follow {
  font-family: poppins;
  font-weight: 500;
  font-size: 14px;
  color: black;
  padding-bottom: 25px;
  padding-right: 10px;
}

.footer-item-content-height, .footer-item-content {
  font-family: roboto;
  font-weight: 100;
  font-size: 18px;
  color: black;
}

.footer-item-content a {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 10px!important;
  margin-bottom: 10px;
}

.footer-item-content a:hover {
  color: #036cab;
}

.footer-item-content p {
  color: black;
  margin: 0;
}

.footer-item-content-height p {
  color: black;
  margin: 0;
  font-weight: 500;
  padding-bottom: 13px;
  font-size: 15px;
  letter-spacing: 1px;
}

.footer-item-content-newsletter-height p {
  color: black;
  margin: 0;
  font-family: poppins;
  font-weight: 300;
  padding-bottom: 13px;
}

.footer-item-content .follow {
  padding-top: 30px;
  font-size: 19px;
  font-family: roboto;
  font-weight: 300;
}

.footer-subscription {
  font-family: poppins;
  font-size: 17px;
  color: white;
  line-height: 71px;
  padding: 0 10px;
  background-color: #005b9d;
  border-radius: 19px 19px 19px 19px;
  padding: 10px 20px;
}

.footer-subscription-block{
  display: flex;
  justify-items: center;
  gap: 7px;
}

.footer-input-subscription:focus,
.footer-input-subscription {
  width: 70%!important;
  background-color: white!important;
  border-radius: 0!important;
  border: 0;
}

.footer-input-subscription::placeholder {
  font-family: roboto;
  font-weight: 100;
  font-size: 11px;
  color: #8b8b8b;
  height: 30px!important;
}

.btn-footer-subscription:focus,
.btn-footer-subscription:hover,
.btn-footer-subscription {
  width: 30%;
  height: 30px;
  color: white;
  background-color: #60b244;
  font-size: 11px;
  border-radius: 0!important;
  border: 0;
}

/* .ant-input:placeholder-shown {
  height: 50px;
} */

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* Startup Form Page */

.en_startup-form-container {
  background-color: #f8f8f8;
  height: 100vh;
}

.en_startup-form-fixed-background-image {
  position: fixed;
  height: 100%;
  width: 43%;
  left: 0%;
  top: 0%;
  filter: brightness(0.7);
}

.en_fixed-background-image-text {
  position: fixed;
  bottom: 20%;
  left: 7%;
  font-family: poppins;
  font-weight: 700;
  color: white;
  font-size: 35px;
  letter-spacing: 4.5px;
  line-height: 42px;
}

.en_fixed-startup-vector {
  background-color: #006caf;
  height: 100vh;
  width: 6.3%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.en_fixed-left-arrow {
  position: fixed;
  max-width: 3%;
  top: 3%;
  left: 1%;
}

.en_fixed-oriented-text {
  font-family: poppins;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2.4px;
  position: fixed;
  left: 1%;
  bottom: 3%;
  color: white;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.en_startup-form-background {
  width: 57%;
  float: right;
  padding: 10px 70px;
  background-color: #f8f8f8;
}

.en_startup-form-title {
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 18px;
  color: #036CAC;
}

.en_startup-form-description-content {
  font-family: poppins;
  font-weight: 200;
  font-size: 17px;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
}

.inline-inputs {
  display: flex;
}

/* Investor Form Page */

.en_investor-form-container {
  background-color: #f8f8f8;
  height: 100vh;
}

.en_investor-form-fixed-background-image {
  position: fixed;
  height: 110vh;
  width: 43%;
  left: 0%;
  top: 0;
  filter: brightness(0.7);
}

.en_fixed-investor-vector {
  background-color: #006caf;
  height: 100vh;
  position: fixed;
  width: 6.3%;
  top: 0;
  left: 0;
  display: none;
}

.en_fixed-left-arrow {
  position: fixed;
  max-width: 3%;
  top: 3%;
  left: 1%;
}

.en_fixed-oriented-text {
  font-family: poppins;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2.4px;
  position: fixed;
  left: 1%;
  bottom: 3%;
  color: white;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.en_investor-form-background {
  width: 57%;
  float: right;
  padding: 10px 70px;
  background-color: #f8f8f8;
}

.en_investor-form-title {
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 18px;
  color: #036CAC;
}

.en_investor-form-description-content {
  font-family: poppins;
  font-weight: 200;
  font-size: 17px;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
}

.inline-inputs {
  display: flex;
  }

/* Login Form Section */

.en_login-form-container{
  background: url('./img/login-background.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0;
  z-index: 5000;
}

.login-card{
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.login-card-title{
  text-align: center;
  color: #036CAC;
  font-family: poppins;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.8px;
  padding-top: 60px;
  padding-bottom: 40px;
}

.login-email{
  height: 40px!important;
  margin-bottom: 20px;
}
.login-email::placeholder{
  text-align: center;
}

.login-password{
  height: 40px!important;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1!important;
}

.login-password .ant-input{
  border-radius: 0!important;
  box-shadow: 0 0 0;
}

.login-password input::placeholder{
  text-align: center;
  padding-left: 15px;
}

.btn-login:focus,
.btn-login:hover,
.btn-login {
  background-color: #60b244;
  color: white;
  font-family: poppins;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 35px 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}

.arrow-login{
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  border: 3px solid white;
  margin: 0 15px!important;
}

.login-text{
  color: #036CAC;
  font-size: 14px;
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-align: center;
}

.login-text-one:hover,
.login-text-one{
  font-family: poppins;
  font-weight: 300;
  font-size: 11px;
  text-decoration: underline;
  color: black;
  margin-bottom: 60px;
}

.make-impact-text{
  font-weight: 700;
}

.login-text-two{
  font-size: 13px;
  margin-top: 70px;
  margin-bottom: 25px;
  font-family: poppins;
  font-weight: 300;
  color: black;
  letter-spacing: 1.2px;
}

.btn-login-one:focus,
.btn-login-one:hover,
.btn-login-one{
  background-color: #60b244;
  color: white;
  font-family: poppins;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 35px 15px;
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
}

.arrow-login-one{
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  border: 3px solid white;
  margin: 0 15px!important;
}

/* forgot password page */

.forgot-password-card-title{
  text-align: center;
  color: #036CAC;
  font-family: poppins;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.8px;
  padding-top: 60px;
  padding-bottom: 30px;
}

.forgot-password-text{
  font-size: 13px;
  margin-bottom: 80px;
  font-family: poppins;
  font-weight: 300;
  color: black;
  letter-spacing: 1.2px;
  padding: 0 6%;
  text-align: center;
}

.forgot-password-email{
  height: 40px!important;
  margin-bottom: 30px;
}
.forgot-password-email::placeholder{
  text-align: center;
}

.btn-forgot-password:focus,
.btn-forgot-password:hover,
.btn-forgot-password {
  background-color: #60b244;
  color: white;
  font-family: poppins;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 35px 15px;
  margin-bottom: 100px;
  display: flex;
  align-items: baseline;
}

.arrow-forgot-password{
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  border: 3px solid white;
  margin: 0 15px!important;
}


/* Animations */

[data-aos="home-btn-animation"] {
  transform-origin: 0% 100%;
  transform: scale(0);
}

[data-aos="home-btn-animation"].aos-animate {
  transform: scale(1);
}

/* Member Space */
.member-space .ant-menu-root.ant-menu-inline{
  color: white;
}

.member-space .ant-menu-title-content{
  color: white;
  font-family: poppins;
  font-weight: 700;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon, .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon{
  color: white;
  font-family: poppins;
  font-weight: 700;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  border-left: 5px solid white;
  background-color: unset;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
  background-color: #036CAC;
  height: 150vh;
}
.ant-layout-sider {
  min-width: 25.5%!important;
  width: 25.5%!important;
  flex: 0 0 25.5%!important;
  max-width: 25.5%!important;
  transition: 0.8s ease;
}
.ant-layout-sider-collapsed{
  min-width: 6.4%!important;
  width: 6.4%!important;
  flex: 0 0 6.4%!important;
  max-width: 6.4%!important;
  transition: 0.8s ease;
}

.member-space-content{
  position: absolute;
  right: 0;
  width: 74.5%;
  background-color: white;
  padding-left: 115px;
  padding-top: 20px;
  transition: width 0.8s;
}

.member-space-content-collapsed{
  position: absolute;
  right: 0;
  width: 93.6%;
  background-color: white;
  padding-left: 115px;
  padding-top: 20px;
  transition: width 0.8s;
}

.member-space-header{
  display: flex;
}

.avatar-member-space{
  max-width: 135px;
  border-radius: 100%;
  padding-bottom: 30px;
}

.member-space-update-icon{
  max-width: 4%;
  margin-right: 5px;
}

.member-space-update-text{
  color: #036CAC;
  font-family: poppins;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.ant-layout-sider-children{
  background-color: #036CAC!important;
} 

.member-space-sidebar{
  position: fixed;
}

.fixed-member-space-woman-collapsed{
  position: fixed;
  right: 1%;
  bottom: 0;
  max-width: 27.5%;
  transition: 0.8s ease;
}

.fixed-member-space-woman{
  position: fixed;
  right: 1%;
  bottom: 0;
  max-width: 16%;
  transition: 0.8s ease;
}

.member-space-logout-icon{
  position: fixed;
  right: 3%;
  top: 15px;
  max-width: 25px;
  cursor: pointer;
}

.member-space-logout-avatar{
  width: 30px;
  display: block;
  border-radius: 50px;
  padding-bottom: 10px;
  cursor: pointer;
}

.member-space-logout-name{
  font-family: poppins;
  font-weight: 700;
  font-size: 12px;
  color: black;
  margin-bottom: 20px;
}

.member-space-logout-email{
  font-family: poppins;
  font-weight: 300;
  font-size: 11px;
  color: black;
}

.member-space-logout-btn{
  color: white;
  border-radius: 0;
  background-color: #036CAC;
  font-family: poppins;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  /* margin-bottom: 20px; */

  box-shadow: 0 0 0;
  width: 100%;
}

.ant-popover-placement-bottomRight {
  position: fixed;
}

.member-space-logout{
  padding-top: 15px;
  padding-bottom: 20px;
}

.sidebar-icon{
  align-self: baseline;
  justify-content: center!important;
}

/* personal Info form */
.member-space-form{
  max-width: 70.5%;
}

.member-space-form .ant-form-item-label>label{
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 5px;
  padding-left: 3px;
}

.member-spacing{
  margin-bottom: 30px;
}

/* Document Section */
.doc-section{
  padding-top: 120px;
}

.doc-container{
  display: flex;
  flex-direction: column;
}

.doc-item{
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 40px;
  width: 61%;
}

.doc-items{
  display: flex;
  align-items: center;
}

.doc-modal{
  
  color: red;
  background-color: black;
}

.doc-title{
  font-family: poppins;
  font-weight: 700;
  color: black;
  font-size: 16px;
  padding-left: 30px;
}

.download-link{
  margin-left: auto;
}

.doc-date{
  font-size: 12px;
  font-weight: 300;
  font-family: poppins;
  color: #6D6D6D;
}

.btn-info-submit{
  font-family: poppins;
  padding: 3px 22px 11px 22px;
  font-size: 15px;
  letter-spacing: 2.15px;
  font-weight: 700;
  color: #036CAC;
  background-color: #FFFFFF;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
}

/* ********* Damya counter page ********** */

.damya-counter-section{
  background-color: white;
  padding: 80px 70px 100px 70px;
}

.damya-counter-title{
  font-size: 38px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 80px;
  text-align: center;
  letter-spacing: 3px;
}

.element-number{
  font-size: 22px;
  font-family: poppins;
  font-weight: 600;
  text-align: center;
  letter-spacing: 3px;
}

.counter-up{
  font-size: 48px;
  font-family: poppins;
  font-weight: 400;
  text-align: center;
  color: #036CAC;
}

/* Single Actuality */

.single-actuality-section{
  background-color: white;
  margin: 0 110px;
  margin-top: 80px;
  margin-bottom: 50px;
}

.single-actuality-inner-section{
  margin: 0 15px;
}

.single-actuality-title{
  font-family: poppins;
  font-weight: 600;
  color: black;
  font-size: 38px;
  padding-top: 35px;
}

.single-actuality-date{
  font-family: 'poppins';
    font-weight: 300;
    font-size: 13px;
    color: white;
    background-color: #60b244;
    padding: 7px 20px;
}

.single-actuality-img{
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.single-actuality-content{
  color: black;
  font-size: 22px;
  line-height: 1.7em;
  padding-top: 60px;
}

.single-actuality-slider{
  margin: 0 28px;
}

.single-actuality-card {
  margin: 0 20px 15px 18px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.single-actuality-card-content{
  padding: 0 10px;
  height: 250px;
}

.new-single-actuality{
  font-family: 'poppins';
  font-weight: 300;
  font-size: 11px;
  color: white;
  background-color: #60b244;
  padding: 7px 20px;
  top: 39.6%;
  position: absolute;
}

.single-actuality-more-news{
  color: #036CAB;
  font-family: poppins;
  font-weight: 700;
  margin-left: 20px;
  margin-top: 60px;
  margin-bottom: 35px;
}

/* section contacteznous*/

.contacteznous-form-container{
  background-color:white;
  padding-top: 100px;
  padding-right: 11%;
  padding-left: 11%;
  padding-bottom: 140px;
}

.contacteznous-card{
  margin-top: 80px;
  background-color: white;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.contacteznous-form{
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 7%;
  }
  .contact-form-title{
    font-family: poppins;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 7%;
    padding-left: 3px;
    color: #036CAC;
  }
  .contact-form .ant-form-item-label>label{
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 600;
    padding-bottom: 5px;
    padding-left: 3px;
  }
  .contact-info{
    display: flex;
    justify-content:space-between;
    gap: 20px!important;
  }
  .contact-form .ant-form-item{
    margin-bottom: 50px;
  }
  .contact-form .ant-input{
    height: 40px;
  }
.contacteznous-text{
  font-weight: 700;
  font-family: "Poppins";
  font-size: 18px;
  letter-spacing: 1.8px;
  text-align: center;
}
.contact-text-area.ant-input{
  min-height: 120px!important;
}

.contacteznous-icon{
  padding-right: 10px;
  max-width: 100%;
}
.btn-contact:hover,
.btn-contact:focus,
.btn-contact{
  background-color: #60b244;
  color: white;
  font-family:"Poppins-Regular" ;
  font-size: 10px;
  letter-spacing: 2px;
  border: none;
  padding: 10px 5px 30px 18px;
  margin-bottom: 50px;
  text-align: center;
  display: flex;
  align-items: baseline;
}
.contacteznous-title{
  font-size: 38px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 8%;
  align-items: center;
  justify-content: center;
  letter-spacing: 1.8px;
}

.arrow-contact{
  border-radius: 50%;
  padding: 4px;
  font-size: 10px;
  border: 2px solid white;
  margin: 0 15px!important;
}

/************************About us ********************/

.about-us-title{
  font-size: 38px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 7%;
  align-items: center;
  justify-content: center;
}

.about-us-left-side{
  color: black;
  font-weight: 700;
  font-family: poppins;
  font-size: 28px;
  letter-spacing: 0.3px;
  padding-left: 80px;
}

.about-us-content-type{
  padding-bottom: 2%;
  font-size: 17px;
  letter-spacing: 2px;
  font-family: poppins;
  font-weight: 600;
  margin-left: 50px;
  margin-right: 85px;
}
.section-one-about-us{
  margin-top: 5%;
  background-image: url('../assets/img/aboutUsImageSectionOne.png');
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.section-two-about-us{
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
}
.about-us-section-two-our_mission{
background-color: #60b244;
height: 400px;
padding-left: 3%;
padding-right: 3%;
padding-top: 7%;
}
.our_mission-title{
  font-size: 25px;
  letter-spacing: 2px;
  color: #FEFFFF;
  font-family:"Poppins-Bold" ;
}
.our_mission-content{
  font-size: 22px;
  font-family: "Poppins-ExtraLight";
  color: #FEFFFF;
  margin-top: 20px;
}
.about-us-section-two-right-image{
  background-image: url('../assets/img/young-professional-business-team-brainstorming-project1.png');
  background-size: cover;
  height: 400px;
}
.about-us-section-two-left-image{
  background-image: url('../assets/img/young-professional-business-team-brainstorming.png');
  background-size: cover;
  height: 400px;
}
.about-us-section-two-our_mission-two{
  background-color: #036CAB;
  height: 400px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 7%;
  }
  .section-three-about-us{
    margin-top: 100px;
  }
  .about-us-blue-woman{
    max-width: 100%;
    padding-top: 45px;
  }
  .about-us-approch{
    margin-left: 35px;
  }
  .section-three-about-us-image-background{
    position: absolute;
    left: 40%;
    top: 0;
    width: 60%;
    height: auto;
    opacity: 0.3;
  }
  .section-three-about-us-content{
    
    padding-bottom: 5%;
    position: relative;
    z-index: 2;
  }
  .section-three-title{
  font-size: 28px;
  color: black;
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 2px;
  padding-bottom:3%;
  }
  .section-three-subtitle{
    font-size: 18px;
    color: black;
    font-family: poppins;
    font-weight: 700;
    letter-spacing: 2px;
    padding: 4% 0;
    margin: 0;
  }
  .section-three-about-us-text{
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: black;
  }
  .section-three-second-title{
    font-size: 20px;
    color: white;
    font-family:"Poppins-Bold" ;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .section-four-about-us{
    margin-top: 5%;
    /* background-image: url("../assets/img/section-four-abous-us.svg"); */
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: right;
    background-color: #036CAB;
    height: 100%;
    position: relative;
  }
  .section-four-about-us-image-background{
    position: absolute;
    left: 19%;
    top: 0;
    width: 80%;
    height: auto;
    opacity: 0.3;
  }
  .section-four-about-us-text-sous{
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: white;
    padding-top: 3%;
  }
  .section-four-about-us-content{
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 6%;
    padding-bottom: 5%;
    position: relative;
    z-index: 2;
  }
  .section-five-title{
    font-size: 26px;
    font-family:"Poppins-Bold" ;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 2.5%;
    padding-bottom: 7%;
    text-align: center;
    letter-spacing: 2px;
    color: black;
  }
  .btn-about-us-damya-angel:hover,
  .btn-about-us-damya-angel{
    background-color: #60b244;
    color: white;
    font-family: "Poppins-Bold";
    font-size: 11px ;
    letter-spacing: 2px;
    border: none;
    padding: 12px 0 32px 10px;
    margin: 0 auto;
    margin-bottom: 70px;
    display: block;
  }

  .about-us-btn-arrow {
    position: relative;
    border-radius: 50%;
    padding: 4px;
    font-size: 10px;
    top: -2px;
    border: 3px solid white;
    margin: 0 20px 0 35px!important;
  }
  .btn-section-five-about-us-section{
    padding-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
  }

  .editor{
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    padding: 4px 11px;
  }
  @media only screen and (max-width: 1200px){
    .impact-text{
      bottom: 5%;
    }
    .impact-title{
      font-size: 70px;
    }
    .contacteznous-icon{
      padding-right: 10px;
      max-width: 100%;
      display: block;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    .footer-padding{
      padding-left: 13%;
      padding-right: 10%;
    }
  }

@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* startup & investor form  */
  .ant-form-item {
    margin-bottom: 20px;
    padding: 0;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-form-vertical .ant-form-item-label>label, .ant-col-24.ant-form-item-label>label, .ant-col-xl-24.ant-form-item-label>label {
    font-family: poppins;
    font-weight: 700;
    font-size: 11.5px;
    color: black;
  }
  .ant-input {
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }
  a, area, button, [role="button"], input:not([type="range"]), label, select, summary {
    height: 25px;
  }
  textarea {
    height: 50px!important;
  } 
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    height: 25px;
  }
  .ant-select-arrow {
    color: #036CAC;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 23px;
  }

  .en_btn-next,
  .en_btn-next:focus,
  .en_btn-next:hover,
  .en_btn-next:active,
  .en_btn-next:before {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 11px;
    padding: 7px 10px 10px 10px;
  }

  .en_btn-prev, .en_btn-prev:focus, .en_btn-prev:hover, .en_btn-prev:active, .en_btn-prev:before{
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 11px;
    padding: 7px 10px 10px 10px;
  }
  /* ------------ */
  .topBar {
    height: 40px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  .en_topBar-link {
    font-size: 12px;
    border-radius: 0;
    padding: 5px 10px;
  }
  .en_topBar-menu {
    font-family: poppins;
    font-weight: 200;
    font-size: 12px;
    color: white;
    padding: 0 10px;
  }
  /* Section one */
  .mobileVisible {
    display: block;
    float: right;
  }
  .mobileHidden {
    display: none;
  }
  .en_slider-section {
    background: white;
    padding: 0 15px;
  }
  .slider1 {
    max-width: 150%;
    max-height: 120%;
    left: 0; 
    top: 0;
  }
  .home-title {
    font-size: 20px;
    top: 10%;
    letter-spacing: unset;
    line-height: 1;
    text-align: center;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
  }

  .btn-home {
    bottom: 10%;
    flex-direction:column;
    justify-content: center;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    gap: 5px!important;
  }
  .btn-slide {
    padding: 5px 10px 20px 10px;
    font-size: 11px;
    border-radius: 0;
  }
  .btn-slide-damya {
    padding: 5px 10px 20px 10px;
    font-size: 11px;
    border-radius: 0;
  }

  .break-line-2::after{
    display: none;
  }

   /* ************slider2************** */
  .impact-text{
    bottom: 10%;
    font-size: 11px;
    right: 0;
    letter-spacing: unset;
    line-height: 1.5;
  }
  .impact-title{
    top: 10%;
    font-size: 25px;
    letter-spacing: unset;
    line-height: 1;
  }
  .break-line-impact::after{
    
  }
  /* Services Section */
  .en_service-section {
    background: url(./img/invest-background.png) no-repeat 50% 50%;
    padding: 70px 10px;
  }
  .en_title-section-service {
    font-size: 25px;
    padding: 0;
    padding-bottom: 60px;
  }
  .en_service-columns-pad {
    padding: 0 30px;
  }
  .en_service-row-2-pad {
    padding-top: 50px;
  }

  /* Actuality Section */

.title-section-actuality {
  font-size: 25px;
  padding: 0 0 60px 0;
}

.actuality-section {
  background: white;
  margin: 60px 3%;
  margin-bottom: 40px;
}

.actuality-card{
  margin: 0 30px 15px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.card-content{
  padding: 0 10px;
  height: 300px;
}

.actuality-img{
  width: 100%;
  height: 200px;
  display: block!important;
}

.new-actuality{
  font-family: 'poppins';
  font-weight: 300;
  font-size: 11px;
  color: white;
  background-color: #60b244;
  padding: 7px 20px;
  top: 35.6%;
  position: absolute;
}

.actuality-date{
  font-family: 'poppins';
  color: #606060;
  font-size: 14px;
  padding-top: 35px;
  margin: 0;
}

.actuality-title {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  padding-top: 15px;
}

.actuality-text{
  font-family: 'poppins';
  font-weight: 500;
  font-size: 16px;
  padding-top: 0 10%;
  margin-bottom: 80px;
}

.actuality-btn:hover,
.actuality-btn {
  background-color: #60b244;
  color: white;
  font-family: "Open Sans";
  font-size: 9px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 20px 15px;
  position: absolute;
  bottom: 5%;
}

.actuality-btn-arrow{
  border-radius: 50%;
  padding: 3px;
  font-size: 8px;
  border: 1px solid white;
  margin: 0 15px!important;
}

.actuality-next{
  top: 40%;
  right: -4%;
}
.actuality-prev{
  top: 40%;
  left: 0%;
}

.show-all-actuality-btn:focus,
.show-all-actuality-btn:hover,
.show-all-actuality-btn{
  float: none;
  display: block;
  background-color: #60b244;
  color: white;
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 30px 10px;
  margin: auto;
  margin-top: 40px;
}

.actuality-arrow-btn {
  border-radius: 50%;
  padding: 4px;
  font-size: 10px;
  border: 2px solid white;
  margin-right: 10px;
  margin-left: 10px!important;
}

/* Actuality Page section */

/* .title-section-actuality-single {
  font-size: 38px;
    color: #036CAC;
    font-family: "Poppins-Bold";
    padding-bottom: 80px;
    text-align: center;
    letter-spacing: 3px;
} */

.actuality-section-single {
  background-color: white;
  padding: 60px 3%;
  padding-bottom: 40px;
}

.title-section-actuality-single{
  font-size: 28px;
}

  /* Partners Section */

.partner-section {
  background-color: white;
  padding: 80px 4% 100px 4%;
}

.title-section-partner {
  font-size: 28px;
}

  /* Startup Section */
  .en_startup-section-non-relative {
    background: #dcdcdc;
    padding: 0;
  }
  .en_startup-section-relative {}
  .en_office-img {
    max-width: 150%;
    left: 0px;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
  }
  .en_startup-hidden-height {
    max-width: 150%;
  }
  .en_vector-woman {
    display: none;
  }
  .en_relative-rect {
    position: absolute;
    top: 6%;
  }
  .en_rectangle-img {
    max-width: 200%;
    position: unset;
    opacity: 0.7;
  }
  .en_for-startup-title {
    top: 10%;
    position: absolute;
    font-size: 25px;
    letter-spacing: normal;
    left: 0;
    right: 0;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .en_startup-text {
    top: 25%;
    font-size: 15px;
    left: 0;
    right: 0;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .en_startup-text-one {
    left: 0;
    right: 0;
    top: 22%;
    font-size: 11px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .en_arrow-mobile {
    max-width: 50%;
  }
  .en_read-more {
    bottom: 5%;
    right: 0%;
  }
  /* Investment Section */
  .en_invest-section-non-relative {
    background: #dcdcdc;
    padding: 0;
  }
  .en_invest-section-relative {
    background: white;
    padding: 0;
    margin: 70px 0;
    position: relative;
  }
  .en_invest-woman {
    max-width: 150%;
    right: 0px;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
  }
  .en_invest-hidden-height {
    max-width: 150%;
  }
  .en_relative-rect-invest {
    position: absolute;
    top: 5%;
  }
  .en_rectangle-grey {
    max-width: 175%;
    opacity: 0.7;
    position: unset;
  }
  .en_for-investor-title {
    right: 0;
    left: 0;
    top: 10%;
    font-size: 25px;
    letter-spacing: normal;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .en_invest-text {
    top: 25%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .en_invest-text-one {
    left: 0;
    right: 0;
    top: 40%;
    font-size: 11px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .en_invest-read-more {
    bottom: 5%;
    right: 0%;
  }
  .en_vector-woman-invest {
    position: absolute;
    right: 10%;
    max-width: 20%;
    top: 10%;
  }
  /* Board Section */
  .landing-page-board-title {
    font-size: 25px;
    padding: 0 10px;
  }

  .title-section-board{
    color: #036CAC;
    font-family: "Poppins-Bold";
    font-size: 28px;
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .board-text {
    text-align: center;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 14px;
    padding: 0 10%;
    margin-bottom: 20px;
}

  .board-slider{
    padding: 0 6%;
  }

  .board-next {
    right: 0%;
    top: 35%;
  }
  
  .board-prev {
    left: 3%;
    top: 35%;
  }

  .board-member-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 49%;
    padding-bottom: 15px;
  }
  .member-border {
    border: 1px solid #7d8082;
    padding: 15px 30px;
  }
  .member-info {
    background-color: #036cab;
    padding: 1px;
    overflow: hidden;
  }
  .member-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    padding-bottom: 0px;
  }
  .member-name {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    margin: 0;
  }
  .member-position {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
  .about-team {
    font-family: poppins;
    font-size: 17px;
    display: flex;
    justify-content: center;
    padding: 30px 0 0 0;
    text-align: center;
  }
  .pad-board-row {
    padding: 50px 30px 0 30px;
  }

  .board-vector {
    position: absolute;
    top: -15%;
    left: -4%;
    max-width: 40%;
  }
  /* testimony Section */
  .en_title-section-testimony {
    font-size: 25px;
  }
  .en_subtitle-section-testimony {
    font-size: 18px;
  }
  .en_text-section-testimony {
    font-family: poppins;
    font-weight: 200;
    font-size: 14px;
    padding-top: 10px;
  }
  .en_testimony-section-non-relative {
    background: white;
    padding: 100px 30px 20px 30px;
    margin: 0;
  }
  .en_testimony-section-relative {
    background: #dcdcdc;
    padding: 30px 0 0 0;
    margin: 0;
    position: relative;
  }

  .next {
    right: -8%;
    top: 14%;
  }
  
  .prev {
    left: -8%;
    top: 14%;
  }

  .en_text-testimony {
    font-family: poppins;
    font-weight: 200;
    font-size: 18px;
  }
  .en_testimony-name {
    font-family: poppins;
    font-weight: 600;
    text-align: center;
    font-size: 22px;
    color: #036cab;
    padding-top: 15px;
    margin: 0;
  }
  .en_video {
    padding: 40px 0 20px 0;
  }
  /* partner Section */
  .en_title-section-partner {
    font-size: 25px;
  }
  .en_partner-logo-spacing {
    padding: 30px 30px;
  }
  /* footer Section */
  .footer-section-relative {
    padding: 110px 55px;
    padding: 10px 4% 100px 4%;
    margin: 0;
    position: relative;
  }
  .footer-section-relative::after {
    content: "";
    background: url(./img/footer-img.png) no-repeat;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  .footer-email{
    margin-top: 0;
    margin-bottom: 0;
  }
  .logo-footer {
    max-width: 37%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 30px;
    padding-top: 70px;
  }
  .social-icon-inline{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .social-icon {
    padding: 0 8px;
  }
  .footer-item-title {
    padding: 30px 0 25px 0;
    text-align: center;
  }
  .footer-item-content-height, .footer-item-content {
    font-family: roboto;
    font-weight: 100;
    font-size: 18px;
    color: black;
    text-align: center;
  }
  .footer-item-content a {
    color: black;
  }
  .footer-item-content p {
    color: black;
    margin: 0;
  }
  .footer-item-content-height p {
    color: black;
    margin: 0;
    font-weight: 400;
    padding-bottom: 12px;
  }
  .footer-item-content .follow {
    padding-top: 30px;
    font-size: 19px;
    font-family: roboto;
    font-weight: 300;
  }
.footer-input-subscription:focus,
.footer-input-subscription {
  height: 30px!important;
}

.footer-input-subscription::placeholder {
  height: 30px!important;
}
.footer-item-content-newsletter-height p {
  text-align: center;
}
.footer-padding{
  padding-left: 5%;
  padding-right: 5%;
}
  /* Startup Form Page */

.en_startup-form-fixed-background-image {
  display: none;
}

.en_fixed-background-image-text {
  display: none;
}

.en_fixed-startup-vector {
  height: 150vh;
  width: 20%;
  position: fixed;
  top: 0;
  left: 0;
}

.en_fixed-left-arrow {
  max-width: 8%;
  left: 5%;
}

.en_fixed-oriented-text {
  left: 3%;
}

.en_startup-form-background {
  width: 100%;
  float: right;
  padding: 10px 3%;
}

.en_startup-form-description-content {
  font-size: 14px;
}

.mobile-block{
  display: block;
  width: 100%;
}

/* Investor Form Page */

.en_investor-form-fixed-background-image {
  display: none;
}

.en_fixed-background-image-text {
  display: none;
}

.en_fixed-investor-vector {
  height: 150vh;
  width: 20%;
  position: fixed;
  top: 0;
  left: 0;
}

.en_fixed-left-arrow {
  max-width: 8%;
  left: 5%;
}

.en_fixed-oriented-text {
  left: 3%;
}

.en_investor-form-background {
  width: 100%;
  float: right;
  padding: 10px 3%;
}

.en_investor-form-description-content {
  font-size: 14px;
}

  /* Login Form Section */
  .login-email{
    height: 40px!important;
    margin-bottom: 20px;
    width: 80%;
  }
  
  .login-password{
    height: 40px!important;
    margin-bottom: 20px;
    width: 80%;
  }

  .forgot-password-card-title{
    font-size: 18px;
  }

  .forgot-password-text{
    font-size: 11px;
    margin-bottom: 50px;
  }
  
  .forgot-password-email{
    margin-bottom: 50px;
  }

  .btn-forgot-password:focus, .btn-forgot-password:hover, .btn-forgot-password{
    font-size: 11px;
    margin-bottom: 50px;
  }

  /************************About us ********************/

.section-one-about-us{
  margin-top: 60px;
}

.about-us-title{
  font-size: 28px;
  padding-bottom: 40px;
}
.about-us-content-type{
  font-size: 14px;
  text-align: center;
  font-family:"Poppins-ExtraLight" ;
  margin-left: 5%;
  margin-right: 5%;
}
.about-us-left-side{
  padding-left: 0;
  text-align: center;
  font-size: 20px;
  padding-bottom: 30px;
}
.section-three-about-us-text{
  
}
.about-us-section-two-right-image{
  display: none;
}
.about-us-section-two-left-image{
  display: none;
}
.about-us-blue-woman{
  display: none;
}
.section-three-about-us{
  margin-top: 0;
  text-align: center;
}
.section-three-subtitle{
  padding: 12% 0;
  padding-bottom: 5%;
}
.about-us-approch{
  margin: 0;
}
.section-three-title{
  margin-top: 40px;
}
.section-two-about-us{
  margin-left: 0;
  margin-right: 0;
  margin-top: 5%;
}

  .section-three-about-us-content{
    margin-left: 5%;
    margin-right: 5%;
  }
  .section-five-about-us{
    padding: 0 4%;
  }
  .section-five-title{
    text-align: center;
    font-size: 20px;
  }

  /* activities section */
  .nos-activities-title{
    font-size: 28px!important;
  }
  .nos-activities-section{
    background: none!important;
    padding-left: 5%!important;
    padding-right: 5%!important;
  }
  .nos-activities-cards{
    padding-top: 30px!important;
  }
  .nos-activities-text{
    font-size: 16px!important;
}
/* section contacteznous*/
.contacteznous-form-container{
  padding-top: 50px;
  padding-right: 0%;
  padding-left: 0%;
  padding-bottom: 100px;
}
.contacteznous-text{
  font-weight: 700;
  font-family: "Poppins";
  font-size: 15px;
}
.contact-info{
  justify-content: start;
  flex-direction: column;
  gap: 30px!important;
}
.contact-form-title{
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
}
.contacteznous-icon{
  padding-right: 0;
  max-width: 12%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.contact-map-height{
  display: none;
}
.contacteznous-card{
  margin-top: 40px;
  
}
.contacteznous-title{
  font-size: 28px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 50px;
  align-items: center;
  justify-content: center;
}
/* Member Space */
.member-space-update-text{
  font-size: 14px;
}
.avatar-member-space{
  max-width: 15%;
}
.member-space-content{
  padding-left: 7%;
  padding-top: 3%;
  width: 80%;
}
.member-space-content-collapsed{
  padding-left: 7%;
  padding-top: 3%;
  width: 80%;
}
.member-space-update-icon{
  max-width: 25px;
}
.member-space-logout-icon{
  position: absolute;
  width: 15px;
  
}
.member-space-form .ant-form-item-label>label{
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 0px;
  padding-left: 3px;
}
.ant-layout-sider {
  z-index: 1000;
  min-width: 60%!important;
  width: 60%!important;
  flex: 0 0 60%!important;
  max-width: 60%!important;
  transition: 0.8s ease;
}
.ant-layout-sider-collapsed{
  min-width: 20%!important;
  width: 20%!important;
  flex: 0 0 20%!important;
  max-width: 20%!important;
  transition: 0.8s ease;
}
.fixed-member-space-woman{
  display: none;
}
.fixed-member-space-woman-collapsed{
  display: none;
}
/* personal Info form */
.member-space-form{
  max-width: 100%;
  padding-top: 30px;
}
.member-space-form .ant-form-item-label>label{
  font-size: 11px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 0;
  padding-left: 3px;
}
/* personal Info form */
.member-space-form{
  max-width: 100%;
}

.member-space-form .ant-form-item-label>label{
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 5px;
  padding-left: 3px;
}

/* Document Section */
.doc-section{
  padding-top: 120px;
}

.doc-container{
  display: flex;
  flex-direction: column;
}

.doc-item{
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 40px;
  width: 90%;
}

.doc-items{
  display: flex;
  align-items: center;
}

.doc-title{
  font-family: poppins;
  font-weight: 500;
  color: black;
  font-size: 12px;
  padding-left: 15px;
}

.download-link{
  margin-left: auto;
}

.doc-date{
  font-size: 10px;
  font-weight: 300;
  font-family: poppins;
  color: #6D6D6D;
}

.btn-info-submit{
  font-family: poppins;
  padding: 3px 22px 11px 22px;
  font-size: 15px;
  letter-spacing: 2.15px;
  font-weight: 700;
  color: #036CAC;
  background-color: #FFFFFF;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
}
/* ********* Damya counter page ********** */

/* .damya-counter-section{
  background-color: white;
  padding: 80px 70px 100px 70px;
} */

.damya-counter-title{
  font-size: 28px;
}

.element-number{
  font-size: 20px;
  font-family: poppins;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 3em;
}

.counter-up{
  font-size: 38px;
  font-family: poppins;
  font-weight: 400;
  text-align: center;
  color: #036CAC;
}

/* Single Actuality */

.single-actuality-section{
  background-color: white;
  margin: 0 4%;
  margin-top: 45px;
  margin-bottom: 50px;
}

.single-actuality-inner-section{
  margin: 0;
}

.single-actuality-title{
  font-family: poppins;
  font-weight: 600;
  color: black;
  font-size: 20px;
  padding-top: 35px;
}

.single-actuality-date{
  font-family: 'poppins';
    font-weight: 300;
    font-size: 13px;
    color: white;
    background-color: #60b244;
    padding: 7px 20px;
}

.single-actuality-img{
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.single-actuality-content{
  color: black;
  font-size: 20px;
  line-height: 1.7em;
  padding-top: 40px;
}

.single-actuality-slider{
  margin: 0 0;
}

.single-actuality-card {
  margin: 0 25px 15px 25px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.single-actuality-card-content{
  padding: 0 10px;
  height: 250px;
}

.new-single-actuality{
  font-family: 'poppins';
  font-weight: 300;
  font-size: 11px;
  color: white;
  background-color: #60b244;
  padding: 7px 20px;
  top: 39.6%;
  position: absolute;
}

.single-actuality-more-news{
  text-align: center;
  margin-left: 0;
  margin-top: 80px;
  margin-bottom: 35px;
}
}

/* SMARTPHONES LANDSCAPE */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  /* startup & investor form  */
  .ant-form-item {
    margin-bottom: 20px;
    padding: 0;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    flex-direction: unset;
    padding-bottom: 4px;
  }
  .ant-form-vertical .ant-form-item-label>label, .ant-col-24.ant-form-item-label>label, .ant-col-xl-24.ant-form-item-label>label {
    font-family: poppins;
    font-weight: 700;
    font-size: 11.5px;
    color: black;
  }
  .ant-input {
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }
  a, area, button, [role="button"], input:not([type="range"]), label, select, summary {
    height: 25px;
  }
  textarea {
    height: 60px!important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    height: 25px;
  }
  .ant-select-arrow {
    color: #036CAC;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 23px;
  }
  .en_btn-next,
  .en_btn-next:focus,
  .en_btn-next:hover,
  .en_btn-next:active,
  .en_btn-next:before {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .en_btn-prev,
  .en_btn-prev:focus,
  .en_btn-prev:hover,
  .en_btn-prev:active,
  .en_btn-prev:before {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  /* --------------- */
  .topBar {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }
  .mobileVisible {
    display: block;
    float: right;
  }
  .mobileHidden {
    display: none;
  }
  .en_slider-section {
    background: white;
    padding: 0 30px;
    position: relative;
  }
  .slider1 {
    width:100%;
    height:100%;
    position: absolute;
    right: 0%;
    top: 0px;
  }
  .home-title {
    font-size: 30px;
    top: 10%;
  }
 
  .btn-home {
    bottom: 20%;
    gap: 5px!important;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    justify-content: center;
  }

  .btn-slide:hover, .btn-slide{
    font-size: 12px;
    padding: 5px 20px 10px 20px;
  }

  .btn-slide-damya:hover, .btn-slide-damya{
    font-size: 12px;
    padding: 5px 20px 10px 20px;
  }

  /* ************slider2************** */
  .impact-text{
    bottom: 10%;
    font-size: 15px;
    right: 0;
  }
  .impact-title{
    top: 10%;
    font-size: 30px;
  }
  
  /* Services Section */
  .en_service-section {
    background: url(./img/invest-background.png) no-repeat 50% 50%;
  }
  .en_title-section-service {
    font-size: 30px;
    padding: 0;
    padding-bottom: 80px;
  }
  .en_service-columns-pad {
    padding: 0 30px;
  }
  .en_service-row-2-pad {
    padding: 50px 30px 0 30px;
  }

  /* Actuality Section */

.title-section-actuality {
  font-size: 30px;
}

.actuality-section {
  background: white;
  margin: 60px 130px;
  margin-bottom: 130px;
}

.actuality-card{
  margin: 0 30px 15px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.card-content{
  padding: 0 10px;
  height: 300px;
}

.actuality-img{
  width: 100%;
  height: 200px;
  display: block!important;
}

.new-actuality{
  font-family: 'poppins';
  font-weight: 300;
  font-size: 11px;
  color: white;
  background-color: #60b244;
  padding: 7px 20px;
  top: 35.6%;
  position: absolute;
}

.actuality-date{
  font-family: 'poppins';
  color: #606060;
  font-size: 14px;
  padding-top: 35px;
  margin: 0;
}

.actuality-title {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  padding-top: 15px;
}

.actuality-text{
  font-family: 'poppins';
  font-weight: 500;
  font-size: 16px;
  padding-top: 0 10%;
  margin-bottom: 80px;
}

.actuality-btn:hover,
.actuality-btn {
  background-color: #60b244;
  color: white;
  font-family: "Open Sans";
  font-size: 9px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 20px 15px;
  position: absolute;
  bottom: 5%;
}

.actuality-btn-arrow{
  border-radius: 50%;
  padding: 3px;
  font-size: 8px;
  border: 1px solid white;
  margin: 0 15px!important;
}

.actuality-next{
  top: 40%;
  right: -6%;
}
.actuality-prev{
  top: 40%;
  left: -5%;
}

.show-all-actuality-btn:focus,
.show-all-actuality-btn:hover,
.show-all-actuality-btn{
  float: none;
  background-color: #60b244;
  color: white;
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 35px 20px;
  display: block;
  margin: auto;
  margin-top: 40px;
}

.actuality-arrow-btn {
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  border: 3px solid white;
  margin-right: 20px;
  margin-left: 30px!important;
}

/* Actuality Page section */

/* .title-section-actuality-single {
  font-size: 38px;
    color: #036CAC;
    font-family: "Poppins-Bold";
    padding-bottom: 80px;
    text-align: center;
    letter-spacing: 3px;
} */

.actuality-section-single {
  background-color: white;
  padding: 60px 10%;
  padding-bottom: 40px;
}


  /* Startup Section */
  .en_startup-section-non-relative {
    background: #dcdcdc;
    padding: 0;
  }
  .en_office-img {
    max-width: 100%;
    left: 0px;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
  }
  .en_startup-hidden-height {
    max-width: 100%;
  }
  .en_vector-woman {
    position: absolute;
    left: 10%;
    max-width: 20%;
    top: 10%;
  }
  .en_relative-rect {
    position: absolute;
    top: 10%;
  }
  .en_rectangle-img {
    max-width: 130%;
    position: unset;
    right: 0;
  }
  .en_for-startup-title {
    top: 10%;
    position: absolute;
    font-size: 20px;
    letter-spacing: normal;
    left: 5%;
    right: 20%;
  }
  .en_startup-text {
    top: 25%;
    font-size: 12px;
    left: 5%;
    right: 20%;
  }
  .en_startup-text-one {
    top: 60%;
    font-size: 11px;
    left: 5%;
    right: 20%;
  }
  .en_arrow-mobile {
    max-width: 70%;
  }
  .en_read-more {
    bottom: 5%;
    right: 5%;
  }
  /* Investment Section */
  .en_invest-section-non-relative {
    background: #dcdcdc;
    padding: 0;
  }
  .en_invest-section-relative {
    background: white;
    padding: 0;
    margin: 110px 0;
    position: relative;
  }
  .en_invest-woman {
    max-width: 100%;
    left: 0px;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
  }
  .en_invest-hidden-height {
    max-width: 100%;
  }
  .en_relative-rect-invest {
    position: absolute;
    top: 5%;
  }
  .en_rectangle-grey {
    max-width: 115%;
    position: unset;
  }
  .en_for-investor-title {
    top: 25%;
    left: 5%;
    font-size: 20px;
    letter-spacing: normal;
    right: 20%;
  }
  .en_invest-text {
    top: 40%;
    left: 5%;
    font-size: 12px;
    right: 20%;
  }
  .en_invest-text-one {
    left: 5%;
    bottom: 10%;
    font-size: 11px;
  }
  .en_invest-read-more {
    right: 5%;
    bottom: 5%;
  }
  .en_vector-woman-invest {
    position: absolute;
    right: 10%;
    max-width: 20%;
    top: 10%;
  }
  .landing-page-board-title{
    font-size: 30px;
  }
  .board-member-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 49%;
    padding-bottom: 15px;
  }
  .member-border {
    border: 1px solid #7d8082;
    padding: 15px 30px;
  }
  .member-info {
    background-color: #036cab;
    padding: 1px;
    overflow: hidden;
  }
  .member-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    padding-bottom: 40px;
  }
  .member-name {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    margin: 0;
  }
  .member-position {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
  .about-team {
    font-family: poppins;
    font-size: 17px;
    display: flex;
    justify-content: center;
    padding: 30px 0 0 0;
    text-align: center;
  }
  .pad-board-row {
    padding: 50px 100px 0 100px;
  }
  .board-vector {
    position: absolute;
    top: -15%;
    left: -4%;
    max-width: 40%;
  }
  /* testimony Section */
  .en_title-section-testimony {
    font-size: 30px;
  }
  .en_subtitle-section-testimony {
    font-size: 25px;
  }
  .en_text-section-testimony {
    font-family: poppins;
    font-weight: 200;
    font-size: 16px;
    padding-top: 10px;
  }
  .en_testimony-section-non-relative {
    background: white;
    padding: 100px 30px 70px 30px;
    margin: 0;
  }
  .en_testimony-section-relative {
    background: #dcdcdc;
    padding: 30px 0 0 0;
    margin: 0;
    position: relative;
  }
  .en_text-testimony {
    font-family: poppins;
    font-weight: 200;
    font-size: 18px;
  }
  .en_testimony-name {
    font-family: poppins;
    font-weight: 600;
    text-align: center;
    font-size: 27px;
    color: #036cab;
    padding-top: 15px;
    margin: 0;
  }
  .next {
    right: -3%;
    top: 27%;
  }
  
  .prev {
    left: -3%;
    top: 27%;
  }
  .en_video {
    padding: 70px 0 20px 0;
  }
  /* partner Section */
  .en_title-section-partner {
    font-size: 30px;
  }
  .en_partner-logo-spacing {
    padding: 0 30px;
  }
  /* footer Section */
  .footer-section-relative {
    padding: 110px 55px;
    padding: 10px 4% 100px 4%;
    margin: 0;
    position: relative;
  }
  .footer-section-relative::after {
    content: "";
    background: url(./img/footer-img.png) no-repeat;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  .logo-footer {
    max-width: 37%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .social-icon {
    padding: 0 5px;
  }
  .footer-item-title {
    padding: 30px 0 25px 0;
    text-align: center;
  }
  .footer-email{
    margin-top: 0;
  }
  .footer-item-content-height, .footer-item-content {
    font-family: roboto;
    font-weight: 100;
    font-size: 18px;
    color: black;
    text-align: center;
  }
  .footer-item-content a {
    color: black;
  }
  .footer-item-content p {
    color: black;
    margin: 0;
  }
  .footer-item-content-height p {
    color: black;
    margin: 0;
    padding-bottom: 23px;
  }
  .footer-item-content .follow {
    padding-top: 30px;
    font-size: 19px;
    font-family: roboto;
    font-weight: 300;
  }
  .footer-input-subscription {
    width: 50%;
    border-radius: 0!important;
  }
  .footer-input-subscription::placeholder {
    padding: 0;
    text-align: center;
    border-radius: 0!important;
  }
  .footer-input-subscription:focus,
  .footer-input-subscription {

    height: 30px!important;
}
.footer-item-content-newsletter-height p {
  text-align: center;
}
  .social-icon-inline{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  /* Startup Form Page */

.en_startup-form-fixed-background-image {
  display: none;
}

.en_fixed-background-image-text {
  display: none;
}

.en_fixed-startup-vector {
  height: 150vh;
  width: 20%;
  position: fixed;
  top: 0;
  left: 0;
}

.en_fixed-left-arrow {
  max-width: 8%;
  left: 7%;
}

.en_fixed-oriented-text {
  left: 5%;
}

.en_startup-form-background {
  width: 80%;
  float: right;
  padding: 10px 3%;
}

.en_startup-form-description-content {
  font-size: 14px;
}

.mobile-block{
  display: block;
  width: 100%;
}

/* Investor Form Page */

.en_investor-form-fixed-background-image {
  display: none;
}

.en_fixed-background-image-text {
  display: none;
}

.en_fixed-investor-vector {
  height: 150vh;
  width: 20%;
  position: fixed;
  top: 0;
  left: 0;
}

.en_fixed-left-arrow {
  max-width: 8%;
  left: 7%;
}

.en_fixed-oriented-text {
  left: 5%;
}

.en_investor-form-background {
  width: 100%;
  float: right;
  padding: 10px 3%;
}

.en_investor-form-description-content {
  font-size: 14px;
}

.ant-form-item {
  margin-bottom: 20px;
  padding: 0;
}


.mobile-block{
  display: block;
  width: 100%;
}

  /* Login Form Section */

  .login-email{
    height: 40px!important;
    margin-bottom: 20px;
  }
  .login-email::placeholder{
    text-align: center;
  }
  
  .login-password{
    height: 40px!important;
    margin-bottom: 20px;
  }
  .login-password::placeholder{
    text-align: center;
  }

/************************About us ********************/

.section-one-about-us{
  margin-top: 60px;
}

.about-us-title{
  font-size: 28px;
  padding-bottom: 40px;
}
.about-us-content-type{
  font-size: 14px;
  text-align: center;
  font-family:"Poppins-ExtraLight" ;
  margin-left: 5%;
  margin-right: 5%;
}
.about-us-left-side{
  padding-left: 0;
  text-align: center;
  font-size: 20px;
  padding-bottom: 30px;
}
.section-three-about-us-text{
  
}
.about-us-section-two-right-image{
  display: none;
}
.about-us-section-two-left-image{
  display: none;
}
.about-us-blue-woman{
  display: none;
}
.section-three-about-us{
  margin-top: 0;
  text-align: center;
}
.section-three-subtitle{
  padding: 12% 0;
  padding-bottom: 5%;
}
.about-us-approch{
  margin: 0;
}
.section-three-title{
  margin-top: 40px;
}
.section-two-about-us{
  margin-left: 0;
  margin-right: 0;
  margin-top: 5%;
}

  .section-three-about-us-content{
    margin-left: 5%;
    margin-right: 5%;
  }
  .section-five-about-us{
    padding: 0 4%;
  }
  .section-five-title{
    text-align: center;
    font-size: 20px;
  }

  /* activities section */
  .nos-activities-section{
    background: none!important;
  }
  .nos-activities-cards{
    padding-top: 40px!important;
  }
  .nos-activities-text{
    font-size: 16px!important;
}

/* section contacteznous*/
.contacteznous-form-container{
  padding-top: 80px;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 100px;
}

.contacteznous-text{
  font-weight: 700;
  font-family: "Poppins";
  font-size: 15px;
}
.contact-info{
  justify-content: start;
  flex-direction: column;
  gap: 20px!important;
  padding-bottom: 30px;
}
.contact-form-title{
  text-align: center;
  font-size: 25px;
  padding: 26px 0;
}
.contacteznous-icon{
  padding-right: 10px;
  max-width: 12%;
  display: unset;
  margin: 0;
}
.contact-map-height{
  display: none;
}
.contacteznous-card{
  margin-top: 40px;
}
.contacteznous-title{
  font-size: 30px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 8%;
  align-items: center;
  justify-content: center;
}
.contact-form .ant-form-item-label>label{
  font-size: 14px;
}
/* Member Space */
.member-space-update-text{
  font-size: 14px;
}
.avatar-member-space{
  max-width: 15%;
}
.member-space-content{
  padding-left: 40px;
  width: 70%;
}
.member-space-content-collapsed{
  padding-left: 40px;
  width: 80%;
}
.member-space-form .ant-form-item-label>label{
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 0px;
  padding-left: 3px;
}
.ant-layout-sider {
  min-width: 30%!important;
  width: 30%!important;
  flex: 0 0 30%!important;
  max-width: 30%!important;
  transition: 0.8s ease;
}
.ant-layout-sider-collapsed{
  min-width: 20%!important;
  width: 20%!important;
  flex: 0 0 20%!important;
  max-width: 20%!important;
  transition: 0.8s ease;
}
.fixed-member-space-woman{
  display: none;
}
.fixed-member-space-woman-collapsed{
  display: none;
}
/* personal Info form */
.member-space-form{
  max-width: 100%;
}
.member-space-form .ant-form-item-label>label{
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 5px;
  padding-left: 3px;
}
/* Document Section */
.doc-section{
  padding-top: 120px;
}
.doc-item{
  width: 90%;
}
/* Single Actuality */

.single-actuality-section{
  background-color: white;
  margin: 0 4%;
  margin-top: 45px;
  margin-bottom: 50px;
}

.single-actuality-inner-section{
  margin: 0;
}

.single-actuality-title{
  font-family: poppins;
  font-weight: 600;
  color: black;
  font-size: 25px;
  padding-top: 35px;
}

.single-actuality-date{
  font-family: 'poppins';
    font-weight: 300;
    font-size: 13px;
    color: white;
    background-color: #60b244;
    padding: 7px 20px;
}

.single-actuality-img{
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.single-actuality-content{
  color: black;
  font-size: 25px;
  line-height: 1.7em;
  padding-top: 40px;
}

.single-actuality-slider{
  margin: 0 0;
}

.single-actuality-card {
  margin: 0 25px 15px 25px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.single-actuality-card-content{
  padding: 0 10px;
  height: 250px;
}

.new-single-actuality{
  font-family: 'poppins';
  font-weight: 300;
  font-size: 11px;
  color: white;
  background-color: #60b244;
  padding: 7px 20px;
  top: 39.6%;
  position: absolute;
}

.single-actuality-more-news{
  text-align: center;
  margin-left: 0;
  margin-top: 80px;
  margin-bottom: 35px;
}
}

/* TABLETS PORTRAIT */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* startup & investor form  */
  .ant-form-item {
    margin-bottom: 20px;
    padding: 0;
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-form-vertical .ant-form-item-label>label, .ant-col-24.ant-form-item-label>label, .ant-col-xl-24.ant-form-item-label>label {
    font-family: poppins;
    font-weight: 700;
    font-size: 11.5px;
    color: black;
  }
  .ant-input {
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }
  a, area, button, [role="button"], input:not([type="range"]), label, select, summary {
    height: 25px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    height: 25px;
  }
  .ant-select-arrow {
    color: #036CAC;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 23px;
  }
  .en_btn-next, .en_btn-next:focus, .en_btn-next:hover, .en_btn-next:active, .en_btn-next:before {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .en_btn-prev, .en_btn-prev:focus, .en_btn-prev:hover, .en_btn-prev:active, .en_btn-prev:before {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  /* ---------------- */
  .en_slider-section {
    background: white;
    padding: 0 100px;
    position: relative;
  }
  .slider1 {
    max-height: 100%;
    max-width: unset;
    position: absolute;
    right: 0%;
    top: 0px;
  }
  .home-title {
    right: 0;
    top: 15%;
    font-size: 50px;
  }

  .btn-home {
    
  }

  /* ********************slider2****************** */
  .impact-text{
    bottom: 5%;
    font-size: 20px;
  }
  .impact-title{
    top: 10%;
    font-size: 50px;
  }
  /* Services Section */
  .en_service-section {
    background: url(./img/invest-background.png) no-repeat 50% 50%;
  }
  .en_service-row-2-pad {
    padding: 50px 0 0 0;
  }

  /* Actuality Section */

.actuality-section {
  background: white;
  margin: 60px 4%;
  margin-bottom: 130px;
}

.actuality-next{
  top: 40%;
  right: -6%;
}
.actuality-prev{
  top: 40%;
  left: -5%;
}

.show-all-actuality-btn:focus,
.show-all-actuality-btn:hover,
.show-all-actuality-btn{
  float: none;
  display: block;
  background-color: #60b244;
  color: white;
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 11px;
  letter-spacing: 2px;
  border: none;
  padding: 8px 0 35px 20px;
  margin: auto;
  margin-top: 50px;
}

.actuality-arrow-btn {
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  border: 3px solid white;
  margin-right: 20px;
  margin-left: 30px!important;
}

/* Actuality Page section */

/* .title-section-actuality-single {
  font-size: 38px;
    color: #036CAC;
    font-family: "Poppins-Bold";
    padding-bottom: 80px;
    text-align: center;
    letter-spacing: 3px;
} */

.actuality-section-single {
  background-color: white;
  padding: 60px 60px;
  padding-bottom: 40px;
}


  /* Startup Section */
  .en_startup-section-non-relative {
    background: #dcdcdc;
    padding: 0;
  }
  .en_office-img {
    max-width: 100%;
    left: 0px;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
  }
  .en_startup-hidden-height {
    max-width: 100%;
    position: relative;
  }
  .en_vector-woman {
    position: absolute;
    left: 10%;
    max-width: 20%;
    top: 10%;
  }
  .en_relative-rect {
    position: absolute;
    top: 10%;
  }
  .en_rectangle-img {
    max-width: 100%;
    position: unset;
    right: 0;
  }
  .en_for-startup-title {
    letter-spacing: normal;
    top: 10%;
    position: absolute;
  }
  .en_startup-text {
    top: 25%;
  }
  .en_startup-text-one {
    top: 55%;
    right: 20%;
  }
  .en_read-more {
    bottom: 5%;
    right: 5%;
  }
  /* Investment Section */
  .en_invest-section-non-relative {
    background: #dcdcdc;
    padding: 0;
  }
  .en_invest-section-relative {
    background: white;
    padding: 0;
    margin: 90px 0;
    position: relative;
  }
  .en_invest-woman {
    max-width: 100%;
    left: 0px;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
  }
  .en_invest-hidden-height {
    max-width: 100%;
  }
  .en_relative-rect-invest {
    position: absolute;
    top: 8%;
  }
  .en_rectangle-grey {
    max-width: 100%;
    position: unset;
  }
  .en_for-investor-title {
    left: 5%;
    top: 30%;
    letter-spacing: normal;
  }
  .en_invest-text {
    top: 49%;
    left: 5%;
  }
  .en_invest-text-one {
    left: 5%;
    top: 55%;
  }
  .en_invest-read-more {
    right: 5%;
    bottom: 5%;
  }
  .en_vector-woman-invest {
    position: absolute;
    right: 10%;
    max-width: 20%;
    top: 10%;
  }
  .board-tablet {
    display: block;
  }
  .board-member-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 49%;
    padding-bottom: 15px;
  }
  .member-border {
    border: 1px solid #7d8082;
    padding: 15px 30px;
  }
  .member-info {
    background-color: #036cab;
    padding: 1px;
    overflow: hidden;
  }
  .member-content {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
    padding-bottom: 40px;
  }
  .member-name {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    margin: 0;
  }
  .member-position {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
  .flex-board {
    margin-top: 0px;
  }
  .about-team {
    font-family: poppins;
    font-size: 17px;
    display: flex;
    justify-content: center;
    padding: 30px 0 0 0;
    text-align: center;
  }
  .pad-board-row {
    padding: 50px 170px 0 170px;
  }
  .board-vector {
    position: absolute;
    top: -15%;
    left: -4%;
    max-width: 40%;
  }
  /* testimony Section */
  .en_title-section-testimony {
    font-size: 44px;
    color: #606060;
    font-family: "Poppins";
    letter-spacing: 5px;
    font-weight: 600;
    margin: 0;
  }
  .en_subtitle-section-testimony {
    font-family: poppins;
    font-weight: 500;
    font-size: 30px;
    margin: 0;
    padding-top: 15px;
  }
  .en_text-section-testimony {
    font-family: poppins;
    font-weight: 200;
    font-size: 16px;
    padding-top: 10px;
  }
  .en_testimony-section-non-relative {
    background: white;
    padding: 100px 100px 70px 100px;
    margin: 0;
  }
  .en_testimony-section-relative {
    background: #dcdcdc;
    padding: 30px 0 0 0;
    margin: 0;
    position: relative;
  }
  
  .next {
    right: 7%;
    top: 21%;
  }
  
  .prev {
    left: 7%;
    top: 21%;
  }
  .en_text-testimony {
    font-family: poppins;
    font-weight: 200;
    font-size: 18px;
  }
  .en_testimony-name {
    font-family: poppins;
    font-weight: 600;
    text-align: center;
    font-size: 27px;
    color: #036cab;
    padding-top: 15px;
    margin: 0;
  }
  .en_video {
    padding: 70px 0 20px 0;
  }
  /* footer Section */
  .footer-section-relative {
    padding: 110px 55px;
    padding: 10px 4% 100px 4%;
    margin: 0;
    position: relative;
  }
  .footer-section-relative::after {
    content: "";
    background: url(./img/footer-img.png) no-repeat;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  .logo-footer {
    max-width: 37%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top: 30px;
  }
  .footer-email{
    margin-top: 10px;
  }
  .social-icon {
    padding-right:12px;
    width: 50px;
  }
  .footer-item-title {
    font-family: poppins;
    font-weight: 700;
    font-size: 24px;
    color: black;
    padding-bottom: 15px;
  }
  .footer-item-content-height, .footer-item-content {
    font-family: roboto;
    font-weight: 100;
    font-size: 18px;
    color: black;
  }
  .footer-item-content a {
    color: black;
  }
  .footer-item-content p {
    color: black;
    margin: 0;
  }
  .footer-item-content-height p {
    color: black;
    margin: 0;
    padding-bottom: 23px;
  }
  .footer-item-content .follow {
    padding-top: 30px;
    font-size: 19px;
    font-family: roboto;
    font-weight: 300;
  }
  .footer-subscription {
    font-family: poppins;
    font-weight: 700;
    font-size: 20px;
    color: black;
    margin-top: 20px;
  }

  .footer-input-subscription:focus, .footer-input-subscription{
    height: 30px!important;
  }
  .our_mission-content{
   font-size: 20px; 
  }
  .section-five-title{
    font-size: 20px;
  }
  /* Startup Form Page */
  .en_startup-form-fixed-background-image {
    display: none;
  }
  .en_fixed-background-image-text {
    display: none;
  }
  .en_fixed-startup-vector {
    height: 150vh;
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .en_fixed-left-arrow {
    max-width: 8%;
    left: 7%;
  }
  .en_fixed-oriented-text {
    font-size: 20px;
    left: 5%;
  }
  .en_startup-form-background {
    width: 80%;
    float: right;
    padding: 10px 3%;
  }
  .en_startup-form-description-content {
    font-size: 14px;
  }
  .mobile-block {
    display: block;
    width: 100%;
  }
  
  /* Investor Form Page */
  .en_investor-form-fixed-background-image {
    display: none;
  }
  .en_fixed-background-image-text {
    display: none;
  }
  .en_fixed-investor-vector {
    height: 150vh;
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .en_fixed-left-arrow {
    max-width: 8%;
    left: 7%;
  }
  .en_fixed-oriented-text {
    font-size: 20px;
    left: 5%;
  }
  .en_investor-form-background {
    width: 100%;
    float: right;
    padding: 10px 10%;
  }
  .en_investor-form-description-content {
    font-size: 14px;
  }
  .mobile-block {
    display: block;
    width: 100%;
  }
 /* Login Form Section */
 .login-email{
  height: 40px!important;
  margin-bottom: 20px;
}
.login-email::placeholder{
  text-align: center;
}

.login-password{
  height: 40px!important;
  margin-bottom: 20px;
}
.login-password::placeholder{
  text-align: center;
}
/************************About us ********************/

.section-one-about-us{
  margin-top: 60px!important;
}

.about-us-title{
  font-size: 28px!important;
}
.about-us-content-type{
  font-size: 20px!important;
  text-align: center;
  font-family:"Poppins-Regular" ;
  margin-left: 5%!important;
  margin-right: 5%!important;
}

.section-two-about-us{
  margin-left: 0!important;
  margin-right: 0!important;
  margin-top: 5%;
}
  .section-five-about-us{
    padding: 0 4%;
  }
  .section-five-title{
    text-align: center;
  }
/* activities section */

.nos-activities-section{
  background: url('./img/activity-background.png') 40% 100% no-repeat!important;
}
.nos-activities-cards{
  padding-top: 40px!important;
}
.nos-activities-text{
  font-size: 12px!important;
}
/* section contacteznous*/

.contacteznous-text{
  font-weight: 600;
  font-family: "Poppins";
  font-size: 13px;
}
.contacteznous-icon{
  padding-right: 4px;
  max-width:30%;
	height: 30%;
}
.contacteznous-card{
  margin-top: 40px;
}
.contacteznous-title{
  font-size: 30px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 8%;
  align-items: center;
  justify-content: center;
}
/* Member Space */
.member-space-update-text{
  font-size: 14px;
}
.avatar-member-space{
  max-width: 15%;
}
.member-space-content{
  width: 70%;
  padding-left: 60px;
}
.member-space-content-collapsed{
  width: 80%;
  padding-left: 60px;
}
.member-space-form .ant-form-item-label>label{
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  padding-bottom: 0px;
  padding-left: 3px;
}
.ant-layout-sider {
  z-index: 1000;
  min-width: 30%!important;
  width: 30%!important;
  flex: 0 0 30%!important;
  max-width: 30%!important;
  transition: 0.8s ease;
}
.ant-layout-sider-collapsed{
  min-width: 20%!important;
  width: 20%!important;
  flex: 0 0 20%!important;
  max-width: 20%!important;
  transition: 0.8s ease;
}
/* Single Actuality */

.single-actuality-section{
  background-color: white;
  margin: 0 4%;
  margin-top: 45px;
  margin-bottom: 50px;
}

.single-actuality-inner-section{
  margin: 0;
}

.single-actuality-img{
  height: auto;
}

.single-actuality-content{
  font-size: 25px;
}
}

.step-zero-title{
  font-size: 38px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  padding-bottom: 10%;
}
.step-zero-content{
  color: #000000;
  font-size: 16px;
  font-family: "Poppins-Light";
}
.step-zero-content-type{
  padding-bottom: 2%;
}
.step-zero-section{
  margin-top: 5%;
}
.en_btn-next-step-zero{
  width: 338px;
  height: 60px;
  font-family: "Poppins-SemiBold";
  background-color: #60b244;
  float: right;
  padding: 3px 22px 11px 22px;
  font-size: 15px;
  letter-spacing: 2.15px;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
}
.btn-step-zero-section{
  padding-top: 5%;
}
.content-btn-step-zero{
  margin-top: 15px;
}
.image-btn-step-zero{
  position: relative;
  bottom: 8px;
}
.step-zero-content-type-style{
  padding-bottom: 2%;
  font-family:"Poppins-Bold" ;
}
.loader-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8AD;
}
.loader{
  left: 50%;
  top: 30%;
  z-index: 1000;
  position: absolute;
}
.ant-input-number-handler-wrap{
  display: none;
}
.btn-step-thx{
  display: flex;
  align-content: center;
  justify-content: center;
}
.en_btn-next-step-thx{
  width: 338px;
  height: 60px;
  font-family: "Poppins-SemiBold";
  background-color: #60b244;
  float: right;
  padding: 3px 22px 11px 22px;
  font-size: 13px;
  letter-spacing: 2.15px;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 20px;
  cursor: pointer;
}
.step-thx-title{
  font-size: 34px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  align-content: center;
  justify-content: center;
}
.step-thx-title-section{
  padding-bottom: 25%;
  padding-top: 15%;
}
.image-btn-step-thx {
  position: relative;
  bottom: 10px;
}
.step-thx-title-investor{
  font-size: 32px;
  color: #036CAC;
  font-family: "Poppins-Bold";
  align-content: center;
  justify-content: center;
}

  /************************Nos activites********************/
  .nos-activities-section{
    background: url('./img/activity-background.png') 40% 100% no-repeat;
    background-color: white;
    padding: 80px 70px 20px 70px;
    margin-bottom: 80px;
  }

  .nos-activities-row{
    margin-bottom: 80px;
  }
  
  .nos-activities-title{
    font-size: 38px;
    color: #036CAC;
    font-family: "Poppins-Bold";
    padding-bottom: 80px;
    text-align: center;
    letter-spacing: 3px;
  }

  .nos-activities-cards{
    border: 3px solid #036CAB;
    padding: 30px 22px 30px 22px;
    background-color: white;
    height: 100%;
  }

  .nos-activities-icons{
    display: block;
    max-height: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .nos-activities-text{
    font-family: "Poppins-Light";
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    padding-top: 20px;
    margin: 0;
  }

  .nos-activities-text-impact{
    font-family: "Poppins-Bold";
    font-size: 14px;
    letter-spacing: 1.2px;
    margin-bottom: 30px;
    text-align: center;
  }

  .btn-activities-damya-angel:hover,
  .btn-activities-damya-angel{
    background-color: #60b244;
    color: white;
    font-family: "Poppins-Bold";
    font-size: 11px ;
    letter-spacing: 2px;
    border: none;
    padding: 12px 0 32px 10px;
    margin: 0 auto;
    display: block;
  }

  .activities-btn-arrow {
    position: relative;
    border-radius: 50%;
    padding: 4px;
    font-size: 10px;
    top: -2px;
    border: 3px solid white;
    margin: 0 20px 0 35px!important;
  }


  @media only screen and (min-width: 320px) and (max-width: 479px) {
    .ant-form-vertical .ant-form-item{
      flex-direction: unset;
    }
    .en_btn-next-step-zero{
      width: 280px;
    }
    .content-btn-step-zero{
      font-size: 14px;
    }
  }
  .ant-alert-message{
    color: red;
    font-family: 'Poppins-Regular';
  }
  .error-input{
    border-color: red;
  }
  .ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{
    color: seagreen !important;
  }
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon{
    color: seagreen !important;
  }

  /* Sliders */
#title {
  text-align: center;
}

#employee {
  border-collapse: collapse;
  border: 3px solid #ddd;
}

#employee td,
#employee th {
  border: 1px solid #ddd;
  padding: 12px;
}

#employee tr:hover {
  background-color: #ddd;
}

#employee th {
  padding: 10px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}

.opration {
  text-align: center;
}

.button {
  border: none;
  outline: none;
  font-size: 11px;
  font-family: "Quicksand", sans-serif;
  color: #f44336;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f44336;
  background-color: transparent;
}

.button:active {
  border: 1px solid blue;
}

@media not all, (hover: hover){
  a:hover {
    text-decoration: none !important;
}
}
.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.label-candidiature{
  color: #000000;
  font-size: 12px;
  font-weight: bolder;
}
.text-candidiature{
  color: black;
  font-size: 12px;
}
.modalApprouv{
  align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
}
.menu-espace-membre{
  display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 10px;
}
.button-lire-plus{
  cursor: pointer;
  width: 100%;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background-color: #1890ff;
    font-family: 'Poppins-Bold';
}
.modal-button-delete{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-modal-close-x{
  margin-right: 5px !important;
}
.forgot-password-error{
  display: flex;
  align-items: center;
  justify-content: center;
}


