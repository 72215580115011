@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

.soon {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0;
  width: 0;
}

.soon .img{
  position: fixed;
  right: 0%;
}

.soon .comingsoon {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 0.5) 0%,
    rgba(49, 49, 177, 0.5) 50%,
    rgba(49, 49, 177, 0.6) 100%
  );
}

.soon .container {
  width: 100%;
  margin: 15% auto;
}
.soon .col-4 {
  width: 25%;
  float: left;
}

.soon h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .soon .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
